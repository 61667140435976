<template>
  <div class="container scanner-container">
    <qrcode-stream
        ref="stream"
        v-if="!loading"
        class="qrcode-stream"
        :track="paintOutline"
        :formats="['micro_qr_code', 'qr_code', 'data_matrix', 'code_128']"
        @error="onError"
        @detect="onDetect"
        @init="onReady"
    >
      <!-- Camera Controls -->
      <div class="camera-select">
        <select v-model="scannerId" @change="changeCamera">
          <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">
            {{ device.label || `Camera ${devices.indexOf(device) + 1}` }}
          </option>
        </select>
        <i class="fas fa-camera ml-2 select-icon"></i>
      </div>
      <!-- Stream Controls -->
      <div class="control-panel">
        <div class="zoom-group">
          <!-- Zoom Controls -->
          <button class="control-btn" @click="zoomIn" title="Zoom In">
            <i class="fas fa-search-plus"></i>
          </button>
          <button class="control-btn" @click="zoomOut" title="Zoom Out">
            <i class="fas fa-search-minus"></i>
          </button>
        </div>
        <div class="custom-group">
          <!-- Flashlight and Focus -->
          <button class="control-btn" @click="reload" title="Reload Camera">
            <i class="fas fa-sync-alt"></i>
          </button>
          <button class="control-btn" v-if="flashlightSupported" :class="{ active: isFlashlightOn }" @click="toggleFlashlight" title="Flashlight">
            <i class="fas fa-bolt"></i>
          </button>
          <button class="control-btn" @click="focus" v-if="focusSupport" title="Focus">
            <i class="fas fa-crosshairs"></i>
          </button>
        </div>
      </div>
    </qrcode-stream>

    <loading-state
        v-if="loading"
        centered
        overlay
        title="Loading scanner"
    />
  </div>
</template>

<script>
import {QrcodeStream} from '@likewizerepaircurtis/vue-qrcode-reader';
import {BarcodeScanned} from "@/models/barcodeScanned";
import {mapActions} from "vuex";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      scanner: null,
      caps: null,
      zoomSupport: false,
      focusSupport: false,
      isFlashlightOn: false,
      flashlightSupported: false,
      zoom: null,
      result: null,
      loading: false,
      lastScannedTime: 0,
      scannerId: null,
      devices: [],
    };
  },

  mounted() {
    this.listenForBarcodeScanner();
  },
  methods: {
    ...mapActions(['displayToast']),

    async onReady(caps) {
      try {
        const promise = await caps;
        this.caps = promise.capabilities;
        this.scannerId = this.caps.deviceId
        const devices = await this.$refs.stream.listAvailableCameras();
        this.devices = devices.filter(device => device.kind === 'videoinput');
        if (this.devices.length === 0) {
          await this.displayToast({text: "No cameras available. This device is not supported.", type: 'danger'});
          return;
        }


        this.scanner = this.$refs.stream.cameraInstance.stream.getVideoTracks()[0]

        this.zoom = this.scanner.getSettings().zoom || 1;
        this.checkZoomSupport();
        this.checkFlashlightSupport()
        if (this.flashlightSupported) {
          await this.scanner.applyConstraints({
            advanced: [{torch: false}],
          });
        }
        if (this.caps.focusMode) {
          this.focusSupport = this.caps.focusMode.includes('continuous') || this.caps.focusMode.includes('manual');
        }
      } catch (e) {
        console.error("Error initializing camera:", e);
        await this.displayToast({ text: "Camera initialization failed.", type: 'danger' });
      }

    },


    async reload() {
      this.loading = true;
      await this.$refs.stream.reloadCamera();
      this.loading = false;

    },


    async changeCamera() {
      await this.$refs.stream.setCameraById(this.scannerId);
    },

    async onDetect(detectedCodesPromise) {
      const now = Date.now();
      let detectedCodes = await detectedCodesPromise;
      detectedCodes = await detectedCodes.content;
      if (this.isJson(detectedCodes)) {
        detectedCodes = new BarcodeScanned(JSON.parse(detectedCodes));
      }
      if (
          this.result !== detectedCodes &&
          now - this.lastScannedTime > 2000
      ) {

        this.lastScannedTime = now;
        this.result = detectedCodes;
        this.$emit("barcodeHasBeenScanned", detectedCodes);
      }
    },

    focus() {
      if (this.focusSupport) {
        this.scanner.applyConstraints({
          advanced: [{focusMode: 'continuous'}]
        }).catch((error) => {
          console.error("Failed to refocus:", error);
        });
      } else {
        console.warn("Focus not supported on this device.");
      }
    },

    checkZoomSupport() {
      if (this.scanner && this.caps) {
        if (this.caps.zoom) {
          this.zoomSupport = true;
        }
      }
    },

    checkFlashlightSupport() {
      if (this.scanner && this.caps) {
        this.flashlightSupported = !!this.caps.torch
      }
    },

    toggleFlashlight() {
      if(this.flashlightSupported) {
        this.scanner.applyConstraints({
          advanced: [{torch: !this.flashlightOn}],
        });
        this.flashlightOn = !this.flashlightOn;
      }
    },


    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },


    isJson(string) {
      try {
        JSON.parse(string);
      } catch (e) {
        return false;
      }
      return true;
    },

    onError(error) {
      this.displayToast({text: error, type: 'danger'});
    },


    zoomIn() {
      if (this.zoom < this.caps.zoom.max) {
        this.zoom += 0.5;
        this.applyZoom();
      }
    },
    zoomOut() {
      if (this.zoom > this.caps.zoom.min) {
        this.zoom -= 0.5;
        this.applyZoom();
      }
    },
    applyZoom() {
      if (this.zoomSupport) {
        this.scanner.applyConstraints({advanced: [{zoom: this.zoom}]});
      } else {
        console.warn("Zoom not supported on this device or applyConstraints not available.");
      }
    },

    handleBarcodeScanned(data) {
      let barcodeScanned = data;
      if (this.isJson(data)) {
        barcodeScanned = new BarcodeScanned(JSON.parse(data));
      }

      this.$emit("barcodeHasBeenScanned", barcodeScanned);
    },

    /** Listen out for the hand held barcode scanner */
    listenForBarcodeScanner() {
      let self = this;
      document.addEventListener('keypress', (event) => {
        clearTimeout(self.clearJsonTimeout);

        if (event.key.length === 1) {
          if (event.key === "@") {
            self.jsonString += '"';
          } else {
            self.jsonString += event.key;
          }
        }

        if (event.code === "Enter") {
          console.log(self.jsonString)
          self.handleBarcodeScanned(self.jsonString);
        }

        self.clearJsonTimeout = setTimeout(() => {
          self.searching = false;
          self.jsonString = "";
        }, 1000);
      });
    },
  },
};
</script>

<style scoped>


.scanner-container {
  position: relative;
  aspect-ratio: 3 / 1.3;
}

.qrcode-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  border: 2px solid #4a4f68;
  overflow: hidden;
}


.control-panel {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: width 0.3s ease;
}


.control-btn {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none;
  box-sizing: border-box;
}


.control-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.control-btn:active {
  transform: scale(0.95);
}


.control-btn.active {
  background-color: rgba(255, 255, 255, 0.3);
}


.zoom-group,
.custom-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-select {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 5px 10px;
  max-width: 100%;
  overflow: hidden;
}


.camera-select > select {
  appearance: none;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: inherit;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.camera-select > select:hover,
.camera-select > select:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}


.camera-select > select > option {
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding: 10px;
  overflow-wrap: break-word;
}


.camera-select .select-icon {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 8px;
  transition: color 0.3s ease;
}


.camera-select:hover .select-icon {
  color: #fff;
}


@media screen and (max-width: 768px) {
  .camera-select > select {
    font-size: 14px;
    padding: 6px 10px;
  }
  .camera-select .select-icon {
    font-size: 16px;
  }
  .control-panel {
    width: 50px;
    padding: 5px;
  }
  .control-btn {
    width: 36px;
    height: 36px;
    font-size: 10px;
  }
  .scanner-container {
    aspect-ratio: 1.4;
  }
}

</style>
