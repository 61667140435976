import Vue from 'vue'

/** Add Parts */
Vue.component('adding-part-modal', require('Components/repairProcess/addParts/AddingPartModal').default);
Vue.component('part-fault-status-modal', require('Components/repairProcess/addParts/PartFaultStatusModal').default);
Vue.component('part-faulty-modal', require('Components/repairProcess/addParts/PartFaultyModal').default);
Vue.component('part-not-faulty-modal', require('Components/repairProcess/addParts/PartNotFaultyModal').default);
Vue.component('part-serial-no-scan-modal', require('Components/repairProcess/addParts/SerialNumberScanModal').default);
Vue.component('no-parts-required-modal', require('Components/repairProcess/addParts/NoPartsRequiredModal').default);
Vue.component('remove-part-modal', require('Components/repairProcess/addParts/RemovePartModal').default);

/** Add IMEI */
Vue.component('adding-imei-modal', require('Components/repairProcess/addImei/AddingImeiModal').default);
Vue.component('repeat-repair-modal', require('Components/repairProcess/addImei/RepeatRepairModal').default);

/** Animated Icons */
Vue.component('animated-error', require('Components/animatedIcons/ErrorAnimated').default);
Vue.component('animated-info', require('Components/animatedIcons/InfoAnimated').default);
Vue.component('animated-loading', require('Components/animatedIcons/LoadingAnimated').default);
Vue.component('animated-success', require('Components/animatedIcons/SuccessAnimated').default);
Vue.component('animated-warning', require('Components/animatedIcons/WarningAnimated').default);

/** Bulk Repair */
Vue.component('asset-states', require('Components/bulkRepair/AssetStates').default);
Vue.component('cannot-repair-asset-modal', require('Components/bulkRepair/CannotRepairModal').default);

/** Devices */
Vue.component('add-problem-modal', require('Components/device/AddProblemModal').default);
Vue.component('change-address-modal', require('Components/device/ChangeAddress').default);
Vue.component('change-device-modal', require('Components/device/ChangeDeviceModal').default);
Vue.component('device-job-details-card', require('Components/device/JobDeviceDetailsCard').default);
Vue.component('device-revisit-details-card', require('Components/device/RevisitDeviceDetailsCard').default);
Vue.component('job-start-device-details', require('Components/device/JobStartDeviceDetails').default);
Vue.component('problem-symptom-modal', require('Components/device/ProblemSymptoms').default);
Vue.component('request-additional-parts-modal', require('Components/device/RequestAdditionalPartsModal').default);
Vue.component('update-device-status-modal', require('Components/device/DeviceStatusUpdateModal').default);

/** GSPN */
Vue.component('cant-add-serial-modal', require('Components/repairProcess/gspn/CantAddSerialModal').default);
Vue.component('so-warranty-error-modal', require('Components/repairProcess/gspn/SoWarrantyErrorModal').default);

/** Icons */
Vue.component('icon-ber', require('Components/icons/Ber').default);
Vue.component('icon-book-repair', require('Components/icons/BookRepair').default);
Vue.component('icon-box', require('Components/icons/Box').default);
Vue.component('icon-calendar', require('Components/icons/Calendar').default);
Vue.component('icon-calendar-check', require('Components/icons/CalendarCheck').default);
Vue.component('icon-camera', require('Components/icons/Camera').default);
Vue.component('icon-checklist', require('Components/icons/Checklist').default);
Vue.component('icon-cogs', require('Components/icons/Cogs').default);
Vue.component('icon-device-back', require('Components/icons/DeviceBack').default);
Vue.component('icon-device-clean', require('Components/icons/DeviceClean').default);
Vue.component('icon-device-front', require('Components/icons/DeviceFront').default);
Vue.component('icon-device-unknown', require('Components/icons/DeviceUnknown').default);
Vue.component('icon-inbound-shipment', require('Components/icons/InboundShipment').default);
Vue.component('icon-in-house-repair', require('Components/icons/InHouseRepair').default);
Vue.component('icon-invoice', require('Components/icons/Invoice').default);
Vue.component('icon-map', require('Components/icons/Map').default);
Vue.component('icon-mobile', require('Components/icons/Mobile').default);
Vue.component('icon-outbound-shipment', require('Components/icons/OutboundShipment').default);
Vue.component('icon-overtime', require('Components/icons/Overtime').default);
Vue.component('icon-printer', require('Components/icons/Printer').default);
Vue.component('icon-repair-kits-packing', require('Components/icons/RepairKitsPacking').default);
Vue.component('icon-repair-kits-picking', require('Components/icons/RepairKitsPicking').default);
Vue.component('icon-repair-kits-returns', require('Components/icons/RepairKitsReturns').default);
Vue.component('icon-refresh', require('Components/icons/Refresh').default);
Vue.component('icon-reload', require('Components/icons/Reload').default);
Vue.component('icon-light', require('Components/icons/Light').default);
Vue.component('icon-scanner', require('Components/icons/Scanner').default);
Vue.component('icon-search-imei', require('Components/icons/SearchImei').default);
Vue.component('icon-serial-number', require('Components/icons/SerialNumber').default);
Vue.component('icon-signal-none', require('Components/icons/SignalNone').default);
Vue.component('icon-spreadsheet', require('Components/icons/Spreadsheet').default);
Vue.component('icon-stock-pick', require('Components/icons/StockPick').default);
Vue.component('icon-switch-camera', require('Components/icons/SwitchCamera').default);
Vue.component('icon-tablet', require('Components/icons/Tablet').default);
Vue.component('icon-ticket', require('Components/icons/Ticket').default);
Vue.component('icon-toolbox', require('Components/icons/Toolbox').default);
Vue.component('icon-tools', require('Components/icons/Tools').default);
Vue.component('icon-van-clean', require('Components/icons/VanClean').default);
Vue.component('icon-warranty', require('Components/icons/Warranty').default);

/** In-House Repairs */
Vue.component('asset-list-item', require('Components/inHouseRepairs/AssetListItem').default);

/** Job */
Vue.component('job-cant-start-modal', require('Components/job/JobCantStartModal').default);
Vue.component('job-complete-modal', require('Components/job/JobCompleteModal').default);
Vue.component('job-search-card', require('Components/job/JobSearchCard').default);
Vue.component('job-start', require('Components/job/JobStart').default);
Vue.component('mail-in-job-details', require('Components/job/jobDetailsSection/MailInJob').default);
Vue.component('refurbishment-job-details', require('Components/job/jobDetailsSection/RefurbishmentJob').default);
Vue.component('standard-job-details', require('Components/job/jobDetailsSection/StandardJob').default);
Vue.component('update-status-modal', require('Components/job/JobStatusUpdateModal').default);

/** Job List */
Vue.component('job-item', require('Components/jobList/JobListItem').default);

/** Layout */
Vue.component('device-repair-card', require('Components/repairProcess/layout/RepairDeviceCard').default);
Vue.component('fixed-footer', require('Components/repairProcess/layout/TheFooter').default);
Vue.component('job-complete-footer', require('Components/repairProcess/layout/TheJobCompleteFooter').default);
Vue.component('job-complete-nav', require('Components/repairProcess/layout/TheJobCompleteNav').default);
Vue.component('progress-steps', require('Components/repairProcess/layout/TheProgressSteps').default);

/** Misc */
Vue.component('empty-state', require('Components/misc/EmptyState').default);
Vue.component('loading-state', require('Components/misc/LoadingState').default);
Vue.component('search-banner', require('Components/misc/SearchBanner').default);
Vue.component('picking-filter', require('Components/misc/PickingFilter').default);
Vue.component('the-menu', require('Components/misc/TheMenu').default);

/** Modals */
Vue.component('error-modal', require('Components/misc/ErrorModal').default);
Vue.component('unsaved-changes-modal', require('Components/misc/UnsavedChangesModal').default);
Vue.component('confirm-modal', require('Components/misc/ConfirmModal').default);

/** Notes */
Vue.component('note-item', require('Components/notes/NoteItem').default);
Vue.component('note-modal', require('Components/notes/NoteModal').default);

/** Overtime */
Vue.component('overtime-delete-modal', require('Components/overtime/OvertimeDeleteModal').default);
Vue.component('overtime-item', require('Components/overtime/OvertimeItem').default);

/** Repair Complete **/
Vue.component('remove-parts-modal', require('Components/repairProcess/repairComplete/RemovePartsModal').default);

/** Repair Images */
Vue.component('image-item', require('Components/repairProcess/repairImages/ImageItem').default);
Vue.component('repair-image-modal', require('Components/repairProcess/repairImages/ImagePreviewModal').default);

/** Repair Kits */
Vue.component('dashboard-list-item', require('Components/repairKits/DashboardListItem').default);

/** Scanner */
Vue.component('barcode-picker', require('Components/scanner/BarcodePicker').default);
Vue.component('likewize-scanner', require('Components/scanner/LikewizeScanner.vue').default);
Vue.component('scanner', require('Components/scanner/Scanner.vue').default);


/** Shipments */
Vue.component('return-shipment-complete-modal', require('Components/shipments/ReturnShipmentCompleteModal').default);
Vue.component('shipment-complete-modal', require('Components/repairKits/ShipmentCompleteModal').default);

/** Signature */
Vue.component('signature-pad', require('Components/repairProcess/waiver/SignaturePad').default);

/** Start Checklist */
Vue.component('refurbishment-job-start', require('Components/repairProcess/startChecklist/RefurbishmentJobStart').default);
Vue.component('standard-job-start', require('Components/repairProcess/startChecklist/StandardJobStart').default);

/** Forms */
Vue.component('saw-page', require('Components/forms/SawPage').default);
Vue.component('saw-form', require('Components/forms/SawForm').default);
Vue.component('laraform-override', require('Components/forms/LaraFormOverride').default);
